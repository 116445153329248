var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":_vm.isEdit ? '编辑解决方案' : '添加解决方案',"visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"width":"1200px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{staticClass:"form_box",attrs:{"form":_vm.form}},[_c('div',[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              initialValue: _vm.record.name || '',
              rules: [
                { required: true, validator: _vm.checkName, trigger: 'change' } ],
            } ]),expression:"[\n            'name',\n            {\n              initialValue: record.name || '',\n              rules: [\n                { required: true, validator: checkName, trigger: 'change' },\n              ],\n            },\n          ]"}],attrs:{"maxLength":30,"placeholder":"输入名称"}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('name'))+"/30")])],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'description',
            {
              initialValue: _vm.record.description || '',
              rules: [
                { required: true, message: '输入描述', trigger: 'change' } ],
            } ]),expression:"[\n            'description',\n            {\n              initialValue: record.description || '',\n              rules: [\n                { required: true, message: '输入描述', trigger: 'change' },\n              ],\n            },\n          ]"}],attrs:{"rows":4,"maxLength":200}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('description'))+"/200")])],1),_c('a-form-item',{attrs:{"label":"排序（数字越大排序越前）"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'orderDesc',
            {
              initialValue: _vm.record.orderDesc || '',
              rules: [
                { required: true, validator: _vm.noNumber, trigger: 'change' } ],
            } ]),expression:"[\n            'orderDesc',\n            {\n              initialValue: record.orderDesc || '',\n              rules: [\n                { required: true, validator: noNumber, trigger: 'change' },\n              ],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"价格"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'price',
            {
              initialValue: _vm.record.price || '0',
              rules: [
                { required: true, validator: _vm.noNumber, trigger: 'change' } ],
            } ]),expression:"[\n            'price',\n            {\n              initialValue: record.price || '0',\n              rules: [\n                { required: true, validator: noNumber, trigger: 'change' },\n              ],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"宣传图"}},[[_c('span',{staticClass:"info"},[_vm._v(" （最多30张，首张用于封面，可拖拽调整，其他用于应用预览） ")]),_c('uploadImgs',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'covers',
              {
                initialValue: _vm.imgUrlArr,
                rules: [
                  { required: true, message: '请上传宣传图' },
                  { type: 'array', max: 30, message: '最多只能上传30张图片' } ],
              } ]),expression:"[\n              'covers',\n              {\n                initialValue: imgUrlArr,\n                rules: [\n                  { required: true, message: '请上传宣传图' },\n                  { type: 'array', max: 30, message: '最多只能上传30张图片' },\n                ],\n              },\n            ]"}]})]],2),_c('a-form-item',{attrs:{"label":"URL"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'url',
            {
              initialValue: _vm.record.url || '',
            } ]),expression:"[\n            'url',\n            {\n              initialValue: record.url || '',\n            },\n          ]"}],attrs:{"rows":3}})],1),_c('a-form-item',{attrs:{"label":"二维码"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:(['qrFile']),expression:"['qrFile']"}],attrs:{"accept":"image/gif, image/jpeg, image/png","name":"avatar","listType":"picture-card","multiple":false,"beforeUpload":_vm.beforeUpload,"showUploadList":false,"customRequest":_vm.fileUpload},on:{"change":_vm._handleChange}},[(_vm.imageUrl)?_c('img',{staticClass:"img",attrs:{"src":_vm.imageUrl,"alt":"avatar"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传图片")])],1)])],1)],1),_c('div',{staticClass:"trans_box"},[_c('a-form-item',{attrs:{"label":"包含应用"}},[_c('a-transfer',{attrs:{"titles":['可选择应用', '已选择应用'],"data-source":_vm.application,"target-keys":_vm.targetKeys,"render":function (item) { return ("" + (item.name)); },"rowKey":function (record) { return record.id.toString(); },"list-style":{
            width: '300px',
            height: '800px',
          }},on:{"change":_vm.handleChange}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }