<template>
  <div>
    <div class="top">
      <a-button type="primary" icon="plus" @click="openRegister"
        >登记应用</a-button
      >
      <a-input
        v-model="searchVal"
        style="width: 200px"
        allowClear
        @pressEnter="search"
        @change="inputChange"
        ><a-icon slot="prefix" type="search"
      /></a-input>
    </div>
    <!-- 应用表格 -->
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      :rowKey="(record) => record.id"
      @change="handleTableChange"
    >
      <span slot="solution" slot-scope="text, record">
        {{ text ? (text.name ? text.name : '无') : '无' }}
      </span>
      <span slot="activated" slot-scope="text, record">
        <a-switch
          checked-children="开"
          un-checked-children="关"
          v-model="record.activated"
          @change="switchChange($event, record)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="edit(record)">编辑</a>
        <a-divider type="vertical" />
        <a @click="del(record)">删除</a>
      </span>
    </a-table>
    <add-app-register
      v-if="visible"
      :record="record"
      :visible.sync="visible"
      @refresh="refresh"
    ></add-app-register>
  </div>
</template>

<script>
import addAppRegister from './addAppRegister.vue'

const columns = [
  {
    title: '标识',
    dataIndex: 'permission',
    key: 'permission',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'permission' },
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '解决方案',
    dataIndex: 'solution',
    key: 'solution',
    scopedSlots: { customRender: 'solution' },
  },
  {
    title: '描述',
    dataIndex: 'description',
    key: 'description',
    scopedSlots: { customRender: 'description' },
  },
  {
    title: '状态',
    dataIndex: 'activated',
    key: 'activated',
    scopedSlots: { customRender: 'activated' },
  },
  {
    title: '更新时间',
    dataIndex: 'lastModifiedDate',
    key: 'lastModifiedDate',
    scopedSlots: { customRender: 'lastModifiedDate' },
    sorter: (a, b) =>
      new Date(a.lastModifiedDate) - new Date(b.lastModifiedDate),
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  name: 'AppRegisterTab',
  components: {
    addAppRegister,
  },
  data() {
    return {
      searchVal: '',
      visible: false,
      columns,
      data: [],
      pagination: {
        current: 1, // 默认当前页数
        pageSize: 20, // 默认当前页显示数据的大小
        total: 0,
        showTotal: (total) => `总共${total}条`,
      },
      record: {},
    }
  },
  created() {
    this.init()
  },
  methods: {
    /* 筛选应用 */
    search() {
      // console.log(this.searchVal)
      this.getApplication({ name: this.searchVal })
    },
    inputChange() {
      if (!this.searchVal) {
        this.getApplication({ page: 1, size: 20 })
      }
    },
    /* 分页切换 */
    handleTableChange(pagination) {
      // console.log(pagination)
      this.pagination.current = pagination.current
      this.getApplication({
        name: this.searchVal,
        page: pagination.current,
        size: this.pagination.pageSize,
      })
    },
    openRegister() {
      this.record = {}
      this.visible = true
    },
    refresh() {
      /* 刷新 */
      this.visible = false
      this.record = {}
      this.init()
    },
    switchChange(value, record) {
      console.log(value, record)
      let {
        id,
        createdDate,
        createdBy,
        lastModifiedDate,
        lastModifiedBy,
        ...data
      } = record
      this.updateApplication(id, data).then(() =>
        this.$message.success('更新成功')
      )
      /* 应用权限状态开关 */
      if (value) {
        this.$api.appPermissionEnable(record.permission)
      } else {
        this.$api.appPermissionDisable(record.permission)
      }
    },
    edit(record) {
      this.record = record
      this.visible = true
    },
    del(record) {
      let { id, ...data } = record
      let _this = this
      this.$confirm({
        title: '确定删除该条?',
        content: (h) => (
          <div style="color:red;">删除后不可恢复，请谨慎操作</div>
        ),
        onOk() {
          _this.delApp(id).then(() => {
            _this.$message.success('删除成功')
            _this.init()
          })
        },
        onCancel() {
          console.log('Cancel')
        },
        class: 'test',
      })
    },

    init() {
      this.getApplication({
        page: this.pagination.current,
        size: this.pagination.pageSize,
      })
    },
    /* 获取应用列表 */
    getApplication(params = {}) {
      return new Promise((resolve, reject) => {
        this.$api.getRegisterApplicationList(params).then((res) => {
          // console.log(res, '应用列表')
          if (res.code === 0) {
            this.data = res.data.content
            this.pagination.total = Number(res.data.totalElements)
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 更新应用 */
    updateApplication(id, data) {
      return new Promise((resolve, reject) => {
        this.$api.editApplication(id, data).then((res) => {
          if (res.code === 0) {
            // console.log(res, '更新应用')
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 删除应用 */
    delApp(id) {
      return new Promise((resolve, reject) => {
        this.$api.delApplication(id).then((res) => {
          if (res.code === 0) {
            // console.log(res, '删除应用')
            resolve()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>
