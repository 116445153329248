<template>
  <a-modal
    :title="isEdit ? '编辑登记应用' : '登记应用'"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form">
      <a-form-item label="标识" v-if="!isEdit">
        <a-input
          :maxLength="45"
          placeholder="不允许重复"
          v-decorator="[
            'permission',
            { rules: [{ required: true, message: '请输入应用标识' }] },
          ]"
        ></a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('permission') }}/45</span
        >
      </a-form-item>
      <a-form-item label="icon">
        <a-upload
          accept="image/gif, image/jpeg, image/png"
          name="avatar"
          listType="picture-card"
          :multiple="false"
          :beforeUpload="beforeUpload"
          :showUploadList="false"
          @change="_handleChange"
          v-decorator="[
            'qrFile',
            {
              rules: [
                {
                  required: true,
                  message: '请上传icon',
                },
              ],
              initialValue: record.icon || '',
            },
          ]"
          :customRequest="fileUpload"
        >
          <!-- rules: [{ required: true, validator: checkImg }], -->
          <img v-if="imageUrl" :src="imageUrl" alt="avatar" class="img" />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">上传图片</div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item label="名称">
        <a-input
          :maxLength="30"
          v-decorator="[
            'name',
            {
              initialValue: record.name || '',
              rules: [
                {
                  required: true,
                  validator: checkName,
                  trigger: 'change',
                },
              ],
            },
          ]"
        >
        </a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('name') }}/30</span
        >
      </a-form-item>
      <a-form-item label="描述">
        <a-textarea
          :maxLength="200"
          :rows="2"
          v-decorator="[
            'description',
            {
              initialValue: record.description || '',
              rules: [{ required: true, message: '请输入描述' }],
            },
          ]"
        ></a-textarea>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('description') }}/200</span
        >
      </a-form-item>
      <a-form-item label="URL">
        <a-textarea
          :rows="2"
          v-decorator="[
            'url',
            {
              initialValue: record.url || '',
              rules: [{ required: true, message: '请输入url' }],
            },
          ]"
        ></a-textarea>
      </a-form-item>
      <a-form-item label="所属解决方案">
        <a-select
          placeholder="请选择"
          v-decorator="[
            'solutionId',
            {
              initialValue: record.solution
                ? record.solution.id
                  ? record.solution.id
                  : ''
                : '',
            },
          ]"
        >
          <a-select-option
            v-for="item in solutionList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { fullFileUpload } from '@/utils/newFileUpload.js'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'AddAppRegister',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.init()
  },
  data() {
    return {
      confirmLoading: false,
      loading: false,
      form: this.$form.createForm(this),
      imageUrl: '',
      isEdit: Object.keys(this.record).length > 0,
      solutionList: [],
    }
  },
  methods: {
    /* 获取输入框值长度 */
    getItemValueLength(val) {
      const value = this.form.getFieldsValue([val])[val]
      return value ? value.length : '0'
    },
    init() {
      this.imageUrl = this.record.icon || ''
      this.getSolutionList()
    },
    /* 名称校验（防止新增登记应用重名应用） */
    checkName(rule, value, callback) {
      if (value) {
        return Promise.resolve()
      } else {
        return Promise.reject('请输入名称')
      }
    },
    /* 图片校验 */
    checkImg(rule, value, callback) {
      if (this.imageUrl) {
        return Promise.resolve()
      } else {
        return Promise.reject('请上传icon')
      }
    },
    /* 确定添加、修改 */
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true
          /* 判断新增或者修改 */
          if (this.isEdit) {
            /* 更新 */
            let { id, ...other } = this.record
            let data = {
              icon: this.imageUrl,
              name: values.name,
              description: values.description,
              url: values.url,
              solutionId: values.solutionId,
            }
            this.updateApplication(id, data)
              .then(() => {
                this.confirmLoading = false
                this.$message.success('修改成功')
                this.$emit('refresh')
              })
              .catch((err) => {
                console.log(err)
                if (err.code === 1000) {
                  // this.$message.warning('该名称已存在，请输入新的名称')
                }
              })
          } else {
            /* 新增 */
            let data = {
              permission: values.permission,
              activated: true,
              description: values.description,
              name: values.name,
              icon: this.imageUrl,
              url: values.url,
              solutionId: values.solutionId,
            }
            this.addApplication(data)
              .then(() => {
                this.confirmLoading = false
                this.$message.success('添加成功')
                this.$emit('refresh')
              })
              .catch((err) => {
                console.log(err)
                if (err.code === 1000) {
                  // this.$message.warning('该名称已存在，请输入新的名称')
                }
              })
          }
          // console.log(values, this.imageUrl)
        }
      })
      setTimeout(() => {
        this.confirmLoading = false
        // this.$emit('refresh')
      }, 2000)
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
    _handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl
          this.loading = false
        })
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('请上传图片')
      }
      // const isLt2M = file.size / 1024 / 1024 < 2
      // if (!isLt2M) {
      //   this.$message.error('Image must smaller than 2MB!')
      // }
      // return isJpgOrPng && isLt2M
      return isJpgOrPng
    },
    fileUpload(file) {
      // console.log(file, '文件')
      fullFileUpload(file.file)
        .then((res) => {
          // console.log(res)
          this.imageUrl = res.fileUrl
          this.loading = false
        })
        .catch(() => {
          this.setFieldsValue({ qrFile: '' })
        })
    },
    /* 更新应用 */
    updateApplication(id, data) {
      return new Promise((resolve, reject) => {
        this.$api
          .editApplication(id, data)
          .then((res) => {
            if (res.code === 0) {
              // console.log(res, '更新应用')
              resolve()
            } else {
              reject(res)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /* 创建、登记应用 */
    addApplication(data) {
      return new Promise((resolve, reject) => {
        this.$api
          .createdApplication(data)
          .then((res) => {
            if (res.code === 0) {
              // console.log(res, '新增应用')
              resolve()
            } else {
              reject(res)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /* 获取解决方案列表 */
    getSolutionList() {
      return new Promise((resolve, reject) => {
        this.$api.getSolutionList().then((res) => {
          if (res.code === 0) {
            this.solutionList = res.data.content
            // console.log(res, '解决方案列表')
            resolve()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .img {
  width: 102px;
  height: 102px;
}

/deep/ .ant-form-item {
  margin-bottom: 10px;
}
</style>
