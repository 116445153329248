var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":_vm.isEdit ? '编辑登记应用' : '登记应用',"visible":_vm.visible,"confirm-loading":_vm.confirmLoading},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[(!_vm.isEdit)?_c('a-form-item',{attrs:{"label":"标识"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'permission',
          { rules: [{ required: true, message: '请输入应用标识' }] } ]),expression:"[\n          'permission',\n          { rules: [{ required: true, message: '请输入应用标识' }] },\n        ]"}],attrs:{"maxLength":45,"placeholder":"不允许重复"}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('permission'))+"/45")])],1):_vm._e(),_c('a-form-item',{attrs:{"label":"icon"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'qrFile',
          {
            rules: [
              {
                required: true,
                message: '请上传icon',
              } ],
            initialValue: _vm.record.icon || '',
          } ]),expression:"[\n          'qrFile',\n          {\n            rules: [\n              {\n                required: true,\n                message: '请上传icon',\n              },\n            ],\n            initialValue: record.icon || '',\n          },\n        ]"}],attrs:{"accept":"image/gif, image/jpeg, image/png","name":"avatar","listType":"picture-card","multiple":false,"beforeUpload":_vm.beforeUpload,"showUploadList":false,"customRequest":_vm.fileUpload},on:{"change":_vm._handleChange}},[(_vm.imageUrl)?_c('img',{staticClass:"img",attrs:{"src":_vm.imageUrl,"alt":"avatar"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传图片")])],1)])],1),_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            initialValue: _vm.record.name || '',
            rules: [
              {
                required: true,
                validator: _vm.checkName,
                trigger: 'change',
              } ],
          } ]),expression:"[\n          'name',\n          {\n            initialValue: record.name || '',\n            rules: [\n              {\n                required: true,\n                validator: checkName,\n                trigger: 'change',\n              },\n            ],\n          },\n        ]"}],attrs:{"maxLength":30}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('name'))+"/30")])],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'description',
          {
            initialValue: _vm.record.description || '',
            rules: [{ required: true, message: '请输入描述' }],
          } ]),expression:"[\n          'description',\n          {\n            initialValue: record.description || '',\n            rules: [{ required: true, message: '请输入描述' }],\n          },\n        ]"}],attrs:{"maxLength":200,"rows":2}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('description'))+"/200")])],1),_c('a-form-item',{attrs:{"label":"URL"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'url',
          {
            initialValue: _vm.record.url || '',
            rules: [{ required: true, message: '请输入url' }],
          } ]),expression:"[\n          'url',\n          {\n            initialValue: record.url || '',\n            rules: [{ required: true, message: '请输入url' }],\n          },\n        ]"}],attrs:{"rows":2}})],1),_c('a-form-item',{attrs:{"label":"所属解决方案"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'solutionId',
          {
            initialValue: _vm.record.solution
              ? _vm.record.solution.id
                ? _vm.record.solution.id
                : ''
              : '',
          } ]),expression:"[\n          'solutionId',\n          {\n            initialValue: record.solution\n              ? record.solution.id\n                ? record.solution.id\n                : ''\n              : '',\n          },\n        ]"}],attrs:{"placeholder":"请选择"}},_vm._l((_vm.solutionList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }