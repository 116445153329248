<template>
  <div style="width: 100%" class="upload-img-box">
    <draggable
      class="imagesContent"
      v-bind="{
        group: 'itxst',
        ghostClass: 'moving',
        animation: 180,
        handle: '.image',
      }"
      v-model="fileList"
      @change="$emit('change', fileList)"
    >
      <transition-group>
        <div class="image" v-for="(img, i) in fileList" :key="img.uid">
          <a-icon
            type="close"
            class="delIcon"
            @click="handleRemove(i)"
          ></a-icon>
          <img :src="img.imageUrl" />
        </div>
        <a-upload
          key="upload"
          accept="image/gif, image/jpeg, image/png"
          name="image"
          listType="picture-card"
          :multiple="true"
          :beforeUpload="() => false"
          :showUploadList="false"
          @change="handleChange"
        >
          <div>
            <a-icon type="plus" />
            <div class="ant-upload-text">上传图片</div>
          </div>
        </a-upload>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { fullFileUpload } from '@/utils/newFileUpload.js'

// 将图片文件转换成bese64图片
const fileToBase64 = async (file) =>
  new Promise(function (resolve, reject) {
    const reader = new FileReader()
    let imgResult = ''
    reader.readAsDataURL(file)
    reader.onload = function () {
      imgResult = reader.result
    }
    reader.onerror = function (error) {
      reject(error)
    }
    reader.onloadend = function () {
      resolve(imgResult)
    }
  })

export default {
  name: 'uploadImgs',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    draggable,
  },
  data() {
    return {
      fileList: [],
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.fileList = val
      },
    },
  },
  methods: {
    async handleChange({ file, fileList }) {
      const _file = fileList.find((item) => item.uid === file.uid)
      if (!_file.imageUrl)
        // await fileToBase64(_file.originFileObj).then((res) => {
        //   _file.imageUrl = res
        // })

        await fullFileUpload(_file.originFileObj).then((res) => {
          _file.imageUrl = res.fileUrl
        })

      this.$emit('change', [...this.fileList, _file])
    },

    handleRemove(i) {
      this.fileList.splice(i, 1)
      this.$emit('change', this.fileList)
    },
  },
}
</script>

<style lang="less" scope>
.imagesContent > span {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .image {
    width: 104px;
    height: 104px;
    padding: 2px;
    border: 1px dotted #ddd;
    cursor: pointer;
    margin: 5px;
    position: relative;
    &:hover {
      .delIcon {
        display: block;
      }
    }
    .delIcon {
      display: none;
      position: absolute;
      right: 5px;
      top: 5px;
      font-size: 14px;
      color: #999;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .ant-upload-picture-card-wrapper {
    width: auto;
    margin: 5px;
  }
}
</style>
