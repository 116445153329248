<template>
  <div class="app_register">
    <a-card>
      <a-tabs default-active-key="1" @change="tabChange">
        <a-tab-pane key="1" tab="应用"></a-tab-pane>
        <a-tab-pane key="2" tab="解决方案"> </a-tab-pane>
      </a-tabs>
      <app-register-tab v-if="tabNumber == 1"></app-register-tab>
      <solution-tab v-if="tabNumber == 2"></solution-tab>
    </a-card>
  </div>
</template>

<script>
import appRegisterTab from './modal/appRegisterTab.vue'
import solutionTab from './modal/solutionTab.vue'

export default {
  name: 'ApplicationRegister',
  components: {
    appRegisterTab,
    solutionTab,
  },
  data() {
    return {
      tabNumber: 1,
    }
  },
  methods: {
    tabChange(value) {
      // console.log(value)
      this.tabNumber = value
    },
  },
}
</script>

<style lang="less" scoped>
.app_register {
  min-height: 99%;
  background: #fff;
}
/deep/ .ant-card-bordered {
  border-bottom: none;
}
</style>
