<template>
  <a-modal
    :title="isEdit ? '编辑解决方案' : '添加解决方案'"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    width="1200px"
  >
    <a-form :form="form" class="form_box">
      <div>
        <a-form-item label="名称">
          <a-input
            :maxLength="30"
            placeholder="输入名称"
            v-decorator="[
              'name',
              {
                initialValue: record.name || '',
                rules: [
                  { required: true, validator: checkName, trigger: 'change' },
                ],
              },
            ]"
          ></a-input>
          <span style="position: absolute; bottom: -11px; right: 10px"
            >{{ getItemValueLength('name') }}/30</span
          >
        </a-form-item>
        <a-form-item label="描述">
          <a-textarea
            :rows="4"
            :maxLength="200"
            v-decorator="[
              'description',
              {
                initialValue: record.description || '',
                rules: [
                  { required: true, message: '输入描述', trigger: 'change' },
                ],
              },
            ]"
          ></a-textarea>
          <span style="position: absolute; bottom: -11px; right: 10px"
            >{{ getItemValueLength('description') }}/200</span
          >
        </a-form-item>
        <a-form-item label="排序（数字越大排序越前）">
          <a-input
            v-decorator="[
              'orderDesc',
              {
                initialValue: record.orderDesc || '',
                rules: [
                  { required: true, validator: noNumber, trigger: 'change' },
                ],
              },
            ]"
          ></a-input>
        </a-form-item>
        <a-form-item label="价格">
          <a-input
            v-decorator="[
              'price',
              {
                initialValue: record.price || '0',
                rules: [
                  { required: true, validator: noNumber, trigger: 'change' },
                ],
              },
            ]"
          ></a-input>
        </a-form-item>
        <a-form-item label="宣传图">
          <template>
            <span class="info">
              （最多30张，首张用于封面，可拖拽调整，其他用于应用预览）
            </span>
            <uploadImgs
              v-decorator="[
                'covers',
                {
                  initialValue: imgUrlArr,
                  rules: [
                    { required: true, message: '请上传宣传图' },
                    { type: 'array', max: 30, message: '最多只能上传30张图片' },
                  ],
                },
              ]"
            ></uploadImgs>
          </template>
        </a-form-item>
        <a-form-item label="URL">
          <a-textarea
            :rows="3"
            v-decorator="[
              'url',
              {
                initialValue: record.url || '',
              },
            ]"
          ></a-textarea>
        </a-form-item>
        <a-form-item label="二维码">
          <a-upload
            accept="image/gif, image/jpeg, image/png"
            name="avatar"
            listType="picture-card"
            :multiple="false"
            :beforeUpload="beforeUpload"
            :showUploadList="false"
            @change="_handleChange"
            v-decorator="['qrFile']"
            :customRequest="fileUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" alt="avatar" class="img" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
      </div>
      <div class="trans_box">
        <a-form-item label="包含应用">
          <!-- 穿梭框 -->
          <!-- show-search
            :filter-option="filterOption" -->
          <!-- @search="handleSearch" -->
          <a-transfer
            :titles="['可选择应用', '已选择应用']"
            :data-source="application"
            :target-keys="targetKeys"
            :render="(item) => `${item.name}`"
            :rowKey="(record) => record.id.toString()"
            @change="handleChange"
            :list-style="{
              width: '300px',
              height: '800px',
            }"
          />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import uploadImgs from '@/components/uploadImgs.vue'
import { fullFileUpload } from '@/utils/newFileUpload.js'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'AddSolution',
  components: {
    uploadImgs,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    // console.log(this.isEdit, this.record)
    this.init()
  },
  data() {
    return {
      isEdit: Object.keys(this.record).length > 0,
      confirmLoading: false,
      form: this.$form.createForm(this),
      loading: false,
      imageUrl: '',
      allocationData: [],
      targetKeys: [],
      alreadyTenantIdArr: [],
      alreadyTenantArr: [],
      imgUrlArr: [],
      application: [],
      alreadyApplication: [],
      alreadyApplicationIds: [],
    }
  },
  methods: {
    /* 获取输入框值长度 */
    getItemValueLength(val) {
      const value = this.form.getFieldsValue([val])[val]
      return value ? value.length : '0'
    },
    init() {
      this.imageUrl = this.record.qrcode || ''
      try {
        this.imgUrlArr = this.record.covers.map((item) => {
          return {
            imageUrl: item,
            uid: item,
          }
        })
      } catch {
        this.imgUrlArr = []
      }

      this.getAlreadyApplication()
      this.getApplication().then(() => {
        // console.log(this.application)
        // console.log(this.alreadyApplication)
        // console.log(this.alreadyApplicationIds)
      })
    },
    noNumber(rule, value, callback) {
      if (value) {
        return Promise.resolve()
      } else {
        return Promise.reject('请输入')
      }
    },
    /* 名称校验（防止新增登记应用重名应用） */
    checkName(rule, value, callback) {
      if (value) {
        return Promise.resolve()
      } else {
        return Promise.reject('请输入名称')
      }
    },

    /* 搜索筛选方法 */
    filterOption(inputValue, option) {
      if (
        option.name.indexOf(inputValue) > -1 ||
        option.id.indexOf(inputValue) > -1
      ) {
        return true
      } else {
        return false
      }
    },
    /* 穿梭时触发 */
    handleChange(targetKeys, direction, moveKeys) {
      // console.log(targetKeys, direction, moveKeys)
      // this.targetKeys = targetKeys.reverse()

      if (direction == 'right') {
        this.targetKeys = [...this.targetKeys, ...moveKeys]
      } else {
        this.targetKeys = targetKeys
      }
    },
    /* 穿梭框搜索是触发 */
    handleSearch(direction, value) {},
    /* 确定 */
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // this.confirmLoading = true
          // console.log(values, this.imageUrl)
          let data = {
            name: values.name,
            description: values.description,
            orderDesc: Number(values.orderDesc),
            price: Number(values.price),
            qrcode: this.imageUrl,
            url: values.url,
            covers: values.covers.map((item) => item.imageUrl),
            applicationIds: this.targetKeys.map((item) => Number(item)),
          }
          if (this.isEdit) {
            /* 修改解决方案 */
            let { id, ...other } = this.record
            // console.log(id, data, '修改参数')
            this.updateSolution(id, data)
              .then(() => {
                this.confirmLoading = false
                this.$message.success('修改成功')
                this.$emit('refresh')
              })
              .catch((err) => {
                console.log(err)
                if (err.code === 1000) {
                  // this.$message.warning('该名称已存在，请输入新的名称')
                }
              })
          } else {
            /* 新增解决方案 */
            // console.log(data, '新增参数')
            this.addSolution(data)
              .then(() => {
                this.confirmLoading = false
                this.$message.success('新增成功')
                this.$emit('refresh')
              })
              .catch((err) => {
                console.log(err)
                if (err.code === 1000) {
                  // this.$message.warning('该名称已存在，请输入新的名称')
                }
              })
          }
          this.appPermissionList()
        }
      })
      // setTimeout(() => {
      //   this.confirmLoading = false
      //   this.$emit('refresh')
      // }, 2000)
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
    _handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl
          this.loading = false
        })
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('请上传图片')
      }
      // const isLt2M = file.size / 1024 / 1024 < 2
      // if (!isLt2M) {
      //   this.$message.error('Image must smaller than 2MB!')
      // }
      return isJpgOrPng
    },
    fileUpload(file) {
      // console.log(file, '文件')
      fullFileUpload(file.file).then((res) => {
        // console.log(res)
        this.imageUrl = res.fileUrl
        this.loading = false
      })
    },
    /* 修改、更新解决方案 */
    updateSolution(id, data) {
      return new Promise((resolve, reject) => {
        this.$api
          .editSolution(id, data)
          .then((res) => {
            if (res.code === 0) {
              // console.log(res, '编辑解决方案')
              resolve()
            } else {
              reject(res)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /* 新增解决方案 */
    addSolution(data) {
      return new Promise((resolve, reject) => {
        this.$api
          .cratedSolution(data)
          .then((res) => {
            if (res.code === 0) {
              // console.log(res, '新增解决方案')
              resolve()
            } else {
              reject(res)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /* 获取应用列表 */
    getApplication() {
      return new Promise((resolve, reject) => {
        this.$api.getRegisterApplicationList().then((res) => {
          // console.log(res, '应用列表')
          // console.log(this.targetKeys)
          if (res.code === 0) {
            this.application = res.data.content
            this.application.forEach((item) => {
              this.$set(
                item,
                'disabled',
                Object.keys(item.solution).length > 0 &&
                  !this.targetKeys.includes(item.id.toString())
              )
            })

            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 获取选中的应用列表 */
    getAlreadyApplication() {
      this.alreadyApplication = this.record.applications || []
      this.alreadyApplicationIds = this.alreadyApplication.map((item) =>
        item.id.toString()
      )
      this.targetKeys = this.alreadyApplicationIds
      if (this.isEdit) {
        // let alreadyAppId = this.record.applications.mao((item) => item.id)
        // this.application.forEach((item) => {
        //   if (alreadyAppId.includes(item.id)) {
        //     this.$set(item, 'disabled', true)
        //   }
        // })
      }
    },

    /* 解决方案下的应用列表的权限开关 */
    async appPermissionList() {
      /* 筛选选中应用中，没有开启权限的应用（开启权限） */
      console.log(
        this.targetKeys,
        '当前选中',
        this.alreadyApplicationIds,
        '初始选中'
      )
      let enableAppListIds = this.targetKeys.filter((item) => {
        return !this.alreadyApplicationIds.includes(item)
      })
      // console.log(enableAppListIds, '需要开启权限的应用')
      /* 筛选取消选中的应用（关闭权限） */
      let disableAppListIds = this.alreadyApplicationIds.filter((item) => {
        return !this.targetKeys.includes(item)
      })
      // console.log(disableAppListIds, '需要关闭权限的应用')

      enableAppListIds = enableAppListIds.map((item) => {
        let newItem = this.application.filter((_item) => {
          return _item.id == item
        })[0]
        return newItem.permission
      })
      // console.log(enableAppListIds)

      disableAppListIds = disableAppListIds.map((item) => {
        let newItem = this.application.filter((_item) => {
          return _item.id == item
        })[0]
        return newItem.permission
      })
      // console.log(disableAppListIds)

      /* 发送应用权限启用 */
      enableAppListIds.forEach((item) => {
        this.$api.appPermissionEnable(item).then((res) => {
          // console.log(res)
        })
      })

      /* 发送应用权限关闭 */
      disableAppListIds.forEach((item) => {
        this.$api.appPermissionDisable(item).then((res) => {
          // console.log(res)
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .img {
  width: 102px;
  height: 102px;
}

.form_box {
  display: flex;
  justify-content: space-between;
  & > div:nth-of-type(1) {
    width: 500px !important;
  }
}

/deep/ .ant-form-item {
  margin-bottom: 10px;
}

/deep/ .trans_box {
  // width: 640px;
}
</style>
