<template>
  <div>
    <div style="margin-bottom: 10px">
      <!-- <a-button type="primary" icon="plus" @click="openAddSolution"
        >解决方案</a-button
      > -->
    </div>
    <!-- 解决方案列表 -->
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="false"
      :rowKey="(record) => record.id"
    >
      <span slot="name" slot-scope="text, record">
        {{ record.name }}
      </span>
      <span slot="status" slot-scope="text, record">
        <a-switch
          checked-children="开"
          un-checked-children="关"
          v-model="record.activated"
          @change="switchChange($event, record)"
        />
      </span>

      <span slot="action" slot-scope="text, record">
        <a @click="edit(record)">编辑</a>
        <!-- <a-divider type="vertical" />
        <a @click="del(record)">删除</a> -->
      </span>
    </a-table>
    <add-solution
      :visible.sync="visible"
      :record="record"
      v-if="visible"
      @refresh="refresh"
    ></add-solution>
  </div>
</template>

<script>
import addSolution from './addSolution.vue'

const columns = [
  {
    title: '标识',
    dataIndex: 'permission',
    key: 'permission',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'permission' },
  },
  {
    title: '解决方案名称及描述',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },

  {
    title: '应用数量',
    dataIndex: 'applicationNums',
    key: 'applicationNums',
    scopedSlots: { customRender: 'applicationNums' },
    sorter: (a, b) => a.applicationNums - b.applicationNums,
  },
  {
    title: '价格',
    dataIndex: 'price',
    key: 'price',
    scopedSlots: { customRender: 'price' },
    sorter: (a, b) => a.price - b.price,
  },
  {
    title: '发布时间',
    dataIndex: 'createdDate',
    key: 'createdDate',
    scopedSlots: { customRender: 'createdDate' },
    sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  name: 'SolutionTab',
  components: {
    addSolution,
  },
  data() {
    return {
      visible: false,
      columns,
      data: [],
      record: {},
    }
  },
  created() {
    this.init()
  },
  methods: {
    openAddSolution() {
      this.record = {}
      this.visible = true
    },
    refresh() {
      this.visible = false
      this.record = {}
      this.init()
    },
    edit(record) {
      this.record = record
      this.visible = true
    },
    del(record) {
      let { id, ...data } = record
      let _this = this
      this.$confirm({
        title: '确定删除该条?',
        content: (h) => (
          <div style="color:red;">删除后不可恢复，请谨慎操作</div>
        ),
        onOk() {
          _this
            .delSolution(id)
            .then(() => {
              _this.$message.success('删除成功')
              _this.init()
            })
            .catch((err) => {
              if (err.code === 1000) {
                _this.$message.warning('该解决方案包含应用，请先解除包含应用！')
              }
            })
        },
        onCancel() {
          console.log('Cancel')
        },
        class: 'test',
      })
    },
    init() {
      this.getSolutionList()
    },
    /* 获取解决方案列表 */
    getSolutionList() {
      return new Promise((resolve, reject) => {
        this.$api.getSolutionList().then((res) => {
          if (res.code === 0) {
            this.data = res.data.content
            // console.log(res, '解决方案列表')
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 删除解决方案 */
    delSolution(id) {
      return new Promise((resolve, reject) => {
        this.$api
          .delSolution(id)
          .then((res) => {
            if (res.code === 0) {
              // console.log(res, '删除解决方案')
              resolve()
            } else {
              reject(res)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    switchChange(value, record) {
      console.log(value, record)
      let {
        id,
        createdDate,
        createdBy,
        lastModifiedDate,
        lastModifiedBy,
        ...data
      } = record
      let _data = {
        applicationIds: data.applications.map((item) => item.id),
        covers: data.covers,
        description: data.description,
        name: data.name,
        orderDesc: data.orderDesc,
        price: data.price,
        qrcode: data.qrcode,
        url: data.url,
        activated: data.activated,
      }

      if (value) {
        this.updateSolution(id, _data).then(() => {
          /* 打开解决方案权限 */
          this.$api.permissionEnable(data.permission).then((res) => {
            console.log(res)
          })
          this.$message.success('更新成功')
          this.init()
        })
      } else {
        let _this = this
        this.$confirm({
          title: '关闭该解决方案?',
          content: (h) => (
            <div style="color:red;">该操作影响范围较大，请谨慎操作</div>
          ),
          onOk() {
            console.log('OK')
            _this.updateSolution(id, _data).then(() => {
              /* 关闭解决方案权限 */
              _this.$api.permissionDisable(data.permission).then((res) => {
                console.log(res)
              })
              _this.$message.success('更新成功')
              _this.init()
            })
          },
          onCancel() {
            console.log('Cancel')
            _this.init()
          },
          class: 'test',
        })
      }
    },
    /* 修改、更新解决方案 */
    updateSolution(id, data) {
      return new Promise((resolve, reject) => {
        this.$api
          .editSolution(id, data)
          .then((res) => {
            if (res.code === 0) {
              // console.log(res, '编辑解决方案')
              resolve()
            } else {
              reject(res)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
</script>

<style lang="less" scoped></style>
